import React from "react";
import mac from "../../images/comp-1.png"
import mac2 from "../../images/comp-2.png"
import mac3 from "../../images/comp-3.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container-fluid">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>GET STARTED WITHOUT A PLAN</h6>
                                        <h2>All you need are your thoughts & <br /> voice to get started</h2>
                                        <p>Imagine going on a morning jog and thinking of a course to teach. For 5 days straight, record 5 minute audios. Upload on {appName} and you’re first video course is ready to be published on Udemy or Teachable.</p>
                                        <div className="textL">See how you can use {rebrand ? rebrand.name : appName} to <span>create your first Invoice</span></div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>Polish your videos effortlessly</h6>
                                        <h2>Pour creativity into your videos</h2>
                                        <p>Hate editing templates or thinking of a video script or storyline? Worry not, because {appName} does all the work. Just enter a keyword and choose your content. No need to push your creative boundaries.</p>
                                        <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>TAKE A BACKSEAT</h6>
                                        <h2>Let your team do it all for you</h2>
                                        <p>Nothing to install, nothing to unpack, unzip and absolutely no technical knowledge required. Simply login to {rebrand ? rebrand.name : appName} and start cranking out tons of videos with 100% control and full rights on the videos created.</p>
                                        <div className="textL">Find out how the <span>Team Members Access</span> feature works</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac3} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;