import React from "react";
import { Link } from "react-router-dom";
// import VideoCard from "../project/VideoCard";

const DashboardContent = () => {
    return (
        <>

            <section className="videoProject">
                <div className="container-fluid">
                    <div className="videoProject-top d-none">
                        <h2>Your <span>Business</span></h2>
                        <Link to="/business" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="videoProject-bottom">
                        {/* <VideoCard
                            showRecord={4}
                        /> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;