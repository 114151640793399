import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import BusinessDesign from "./BusinessDesign";
import BusinessGeneral from "./BusinessGeneral";
import BusinessIntregration from "./BusinessIntregration";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

const CreateBusiness = () => {
    return (
        <>
            <TitleBar title="Create Business" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">

                        <div className="business-top mb-4">
                            <h2>Create New Business</h2>
                        </div>

                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                                <Nav className="navSet nav-fill">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">General</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">Design</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">Integration</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="productTabs">
                                    <Tab.Pane eventKey="tab1">
                                        <BusinessGeneral />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <BusinessDesign />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        <BusinessIntregration />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CreateBusiness;