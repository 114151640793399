import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

import { FiArrowRight } from "react-icons/fi"
import {FaBuilding} from "react-icons/fa"

const CreateReminders = () => {
    return (
        <>
        <TitleBar title="Create Reminder" />
        <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">

                       <div className="productNav nav mb-4">
                          <div className="nav-item"><a className="nav-link" href=""><FaBuilding/></a></div>
                          <div className="nav-item"><a className="nav-link" href="/product">Products</a></div>
                          <div className="nav-item"><a className="nav-link" href="/business-analytics">Analytics</a></div>
                          <div className="nav-item"><a className="nav-link" href="/clients">Clients</a></div>
                          <div className="nav-item"><a className="nav-link" href="/coupon">Coupons</a></div>
                          <div className="nav-item"><a className="nav-link active" href="/reminders">Reminders</a></div>
                       </div>

                        <div className="business-top">
                            <h2>Your Reminders</h2>
                            <a className="demoLink mt-0" href="#" >Create Reminder <FiArrowRight/> </a>
                        </div>
                        <div className="business-list">
                            <div className="inpLabelWrap mt-0">
                                <span className="labelTxt">Name</span>
                                <div className="inpLabel">
                                    <label htmlFor="">Name</label>
                                    <input className="inpLabel-inp" placeholder="Name" type="text" />
                                </div>
                            </div>
                            
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Product</span>
                                <div className="inpLabel">
                                    <label htmlFor="">Product</label>
                                    <input className="inpLabel-inp" placeholder="Product" type="text" />
                                </div>
                            </div>
                            
                            <div className="inpLabel d-flex justify-content-start">
                                <label htmlFor="">Type</label>
                                <div className="d-flex">
                                    <div className="custom-radio"> SMS
                                        <input type="radio" checked="checked" name="radio"/>
                                        <span class="checkmark"></span>
                                    </div>
                                    <div className="custom-radio"> email
                                        <input type="radio" name="radio"/>
                                        <span class="checkmark"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="inpLabelWrap">
                                <span className="labelTxt">Content</span>
                                <div className="inpLabel">
                                    <label htmlFor="">Content</label>
                                    <textarea className="inpLabel-inp" name="" id=""></textarea>
                                </div>
                            </div>

                            <div className="btn-sec text-right mt-4">
                               <button className="demoLink">Create Reminder</button>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </section>
        <Footer />
        </>
    )
}

export default CreateReminders;