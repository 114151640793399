import produce from "immer"
const initialState = {
    facebook: [],
    youtube: [],
    scriptreel: [],
    livereel: [],
    trimreel: [],
    syvid: [],
    sonority: [],
    videoreel: []
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return produce(state, (draft) => {
                draft.facebook = action.payload.facebook
                draft.youtube = action.payload.youtube
                draft.scriptreel = action.payload.scriptreel
                draft.livereel = action.payload.livereel
                draft.trimreel = action.payload.trimreel
                draft.syvid = action.payload.syvid
                draft.sonority = action.payload.sonority
                draft.videoreel = action.payload.videoreel ? action.payload.videoreel : []
            })

        case 'ADD_SYVID':
            return produce(state, (draft) => {
                draft.syvid = action.payload
            })

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}