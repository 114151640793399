import React from 'react'
import { FaBuilding } from 'react-icons/fa'
import { Link, NavLink } from 'react-router-dom'

const ViewBusinessNav = (props) => {
    return (
        <div className="productNav nav mb-4">
            <div className="nav-item"><NavLink className="nav-link" to="/dashboard"><FaBuilding /></NavLink></div>
            <div className="nav-item"><NavLink className="nav-link" to="/product">Products</NavLink></div>
            <div className="nav-item"><NavLink className="nav-link" to="/business-analytics">Analytics</NavLink></div>
            <div className="nav-item"><NavLink className="nav-link" to="/clients">Clients</NavLink></div>
            <div className="nav-item"><NavLink className="nav-link" to="/coupon">Coupons</NavLink></div>
            <div className="nav-item"><NavLink className="nav-link" to="/reminders">Reminders</NavLink></div>
        </div>
    )
}

export default ViewBusinessNav