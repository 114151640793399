import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai"
import { Link } from "react-router-dom";
import { onDeleteBusiness, onFetchBusinessList } from "../../Actions/BusinessAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import BusinessTableHeader from "./BusinessTableHeader";

import { FiArrowRight, FiSearch } from "react-icons/fi"
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi"

import Dropdown from 'react-bootstrap/Dropdown'
import dotImg from "../../images/dots.png";

import { BsCodeSlash, BsShare } from 'react-icons/bs'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { SiMaterialdesignicons } from 'react-icons/si'

const Business = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [businessData, setBusinessData] = useState([])
    const [searchKey, setSearchKey] = useState("")
    const [filterVal, setFilterVal] = useState("")


    const fetchBusiness = () => {
        setLoader(true)
        dispatch(onFetchBusinessList(setBusinessData, setLoader))
    }

    const deleteBusiness = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Deleting...',
                    timer: 1000
                })
                let data = {
                    id: id
                }
                dispatch(onDeleteBusiness(data, fetchBusiness))
            }
        })

    }

    useEffect(() => {
        fetchBusiness()
    }, [])


    return (
        <>
            <TitleBar title="Business" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">
                        <div className="business-top">
                            <h2>Your Business</h2>
                            <Link className="demoLink mt-0" to="/create-business">Create New Business <FiArrowRight /> </Link>
                        </div>
                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><FiSearch /></span>
                                    <input
                                        className="inpSearch withIcon"
                                        type="text"
                                        placeholder="Search business name"
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                                <div className="business-search-inp ml-2">
                                    <select
                                        onChange={(e) => setFilterVal(e.target.value)}
                                        className="inpSearch"
                                    >
                                        <option value="">All</option>
                                        <option value="sandbox">Sandbox</option>
                                        <option value="live">Live</option>
                                    </select>
                                </div>
                            </div>

                            <div className="bListing">
                                <table className="table businessTable">
                                    <thead>
                                        <BusinessTableHeader
                                            setBusinessData={setBusinessData}
                                            businessData={businessData}
                                        />
                                    </thead>
                                    <tbody>
                                        {businessData.length > 0 ?
                                            businessData.filter((item) => {
                                                return (item.name.toLowerCase().includes(searchKey.toLowerCase()))
                                            }).filter((filterData) => {
                                                if (filterVal === "") {
                                                    return true
                                                }
                                                return filterData.mode === filterVal
                                            }).map((curElem, index) => {
                                                let logo = JSON.parse(curElem.logo)
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="proThumb">
                                                                <img src={logo.url} alt={logo.name} />
                                                            </div>
                                                        </td>
                                                        <td>{curElem.name}</td>
                                                        <td>{curElem.id}</td>
                                                        {/* <td className="text-capitalize"><i class="fa fa-circle mx-1" style={{ color: curElem.mode === "sandbox" ? "#FF6347" : "#98FB98" }} /> {curElem.mode}</td> */}
                                                        <td>
                                                            {curElem.mode === "sandbox" ?
                                                                <span className="pMode sandbox">SandBox</span> :
                                                                <span className="pMode live">Live</span>
                                                            }
                                                        </td>
                                                        <td>10</td>
                                                        <td>{curElem.modified.split(" ")[0]}</td>
                                                        <td>
                                                        <Link className="roundBox" to={`/view-business?id=${curElem.id}`}><FiExternalLink /></Link>
                                                        <Link className="roundBox"><FiEdit /></Link>
                                                        <Link className="roundBox" onClick={() => deleteBusiness(curElem.id)}><FiTrash2 /></Link>
                                                        
                                                            {/* <Link className="tableLink mr-1" to={`/view-business?id=${curElem.id}`}><AiOutlineEye /></Link>
                                                            <span className="tableLink cursor-pointer" onClick={() => deleteBusiness(curElem.id)}><AiOutlineDelete /></span> */}
                                                        </td>
                                                        <td>
                                                        <Dropdown className="tableDrop">
                                                            <Dropdown.Toggle variant="n">
                                                                <img src={dotImg} alt="" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <a href=""><BsCodeSlash /> Embed Code</a>
                                                                <a href=""><BsShare /> Share</a>
                                                                <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                                                <a href=""><SiMaterialdesignicons /> Page Design</a>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : ""
                                        }
                                    </tbody>
                                </table>
                                {loader ?
                                    <div className="text-center my-4">
                                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                                    </div>
                                    : businessData.length === 0 ? <div className="text-center mt-4 text-capitalize">"No business created yet"</div> : ""
                                }

                                <div className="tablepage">
                                    <ul>
                                        <li><a href=""><BsChevronLeft /></a></li>
                                        <li><a className="active" href="">1</a></li>
                                        <li><a href="">2</a></li>
                                        <li><a href=""><BsChevronRight /></a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Business;