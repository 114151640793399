import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../images/modal-close.png';
import {
    EmailIcon,
    EmailShareButton, FacebookIcon,
    FacebookShareButton, HatenaIcon,
    HatenaShareButton, InstapaperIcon,
    InstapaperShareButton,
    LineShareButton, LinkedinIcon,
    LinkedinShareButton, LivejournalIcon,
    LivejournalShareButton, MailruIcon,
    MailruShareButton, OKIcon,
    OKShareButton,
    PinterestShareButton, PocketIcon,
    PocketShareButton, RedditIcon,
    RedditShareButton, TelegramIcon,
    TelegramShareButton, TumblrIcon,
    TumblrShareButton, TwitterIcon,
    TwitterShareButton, ViberIcon,
    ViberShareButton,
    VKShareButton, WhatsappIcon,
    WhatsappShareButton, WorkplaceIcon,
    WorkplaceShareButton
} from "react-share";
const ModalPopup = ({ show, handleClose, embed, setEmbed, type }) => {

    const [shareUrl, setShareUrl] = useState({
        share: "",
        title: "",
        copied: false
    })
    const copyFunction = (type) => {
        var copyText
        if (type === "share") {
            copyText = document.getElementById("copyUrltoCopy");
            setShareUrl({
                ...shareUrl,
                copied: true
            })
        }
        else if (type === "header") {
            copyText = document.getElementById("headerCode");
            setEmbed({
                ...embed,
                headerStatus: true,
                checkoutStatus: false
            })
        }
        else if (type === "checkout") {
            copyText = document.getElementById("checkoutCode");
            setEmbed({
                ...embed,
                headerStatus: false,
                checkoutStatus: true
            })
        }
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }
    return (
        <Modal className="VideoModal small" show={show} centered>
            <Modal.Body>
                {type === "copy" ?
                    <>
                        <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                        <h4 className='text-dark'>Copy Paste Embed Code</h4>
                        <hr className='text-dark' />
                        <div className='row text-dark'>
                            <div className='col-lg-12' >
                                <label>Copy paste this code in {'<Header></Header>'} of your HTML page </label>
                                <textarea
                                    type="text"
                                    className="inpField-inp text-light my-2"
                                    value={embed.header}
                                    readOnly={true}
                                    style={{ lineHeight: 1.5 }}
                                    id="headerCode"
                                    placeholder="Header Code"
                                    rows={4}
                                />
                                <button
                                    className="copy-button"
                                    onClick={() => copyFunction("header")}
                                >
                                    {embed.headerStatus ?
                                        'Copied' : 'Copy'}
                                </button>
                            </div>
                        </div>
                        <div className='row text-dark'>
                            <div className='col-lg-12' >
                                <label>Copy paste this code in body section of your HTML page </label>
                                <textarea
                                    type="text"
                                    className="inpField-inp text-light my-2"
                                    value={embed.checkout}
                                    readOnly={true}
                                    style={{ lineHeight: 1.5 }}
                                    id="checkoutCode"
                                    placeholder="Checkout Code"
                                    rows={2}
                                />
                                <button
                                    className="copy-button"
                                    onClick={() => copyFunction("checkout")}
                                >
                                    {embed.checkoutStatus ?
                                        'Copied' : 'Copy'}
                                </button>
                            </div>
                        </div>
                    </> :
                    <div className="modalForm">
                        <h2>Share this Product</h2>
                        <div className="socialIcon">
                            <div className="shareLink">
                                <input
                                    class="searchInp full"
                                    type="text"
                                    id="copyUrltoCopy"
                                    value={shareUrl.share}
                                />
                                <button
                                    className="demoLink smBtn mt-0 ml-2"
                                    onClick={() => copyFunction()}
                                >
                                    {shareUrl.copied ? "Copied" :
                                        'Copy'} </button>
                            </div>
                            <div className="Demo__container">
                                <div className="Demo__some-network">
                                    <ul className="pt-5">
                                        <li><FacebookShareButton
                                            url={shareUrl.share}
                                            quote={shareUrl.title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon round />
                                        </FacebookShareButton></li>

                                        <li>
                                            <TwitterShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TwitterIcon round />
                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TelegramIcon round />
                                            </TelegramShareButton>
                                        </li>
                                        <li>
                                            <WhatsappShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                separator=":: "
                                                className="Demo__some-network__share-button"
                                            >
                                                <WhatsappIcon round />
                                            </WhatsappShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url={shareUrl.share} className="Demo__some-network__share-button">
                                                <LinkedinIcon round />
                                            </LinkedinShareButton>
                                        </li>
                                        <li>
                                            <RedditShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                windowWidth={660}
                                                windowHeight={460}
                                                className="Demo__some-network__share-button"
                                            >
                                                <RedditIcon round />
                                            </RedditShareButton>
                                        </li>
                                        <li>
                                            <TumblrShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TumblrIcon round />
                                            </TumblrShareButton>
                                        </li>
                                        <li>
                                            <LivejournalShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                description={shareUrl.share}
                                                className="Demo__some-network__share-button"
                                            >
                                                <LivejournalIcon round />
                                            </LivejournalShareButton>
                                        </li>
                                        <li>
                                            <MailruShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <MailruIcon round />
                                            </MailruShareButton>
                                        </li>
                                        <li>
                                            <EmailShareButton
                                                url={shareUrl.share}
                                                subject={shareUrl.title}
                                                body="body"
                                                className="Demo__some-network__share-button"
                                            >
                                                <EmailIcon round />
                                            </EmailShareButton>
                                        </li>
                                        <li>
                                            <ViberShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <ViberIcon round />
                                            </ViberShareButton>
                                        </li>
                                        <li>
                                            <WorkplaceShareButton
                                                url={shareUrl.share}
                                                quote={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <WorkplaceIcon round />
                                            </WorkplaceShareButton>
                                        </li>
                                        <li>
                                            <PocketShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <PocketIcon round />
                                            </PocketShareButton>
                                        </li>
                                        <li>
                                            <InstapaperShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <InstapaperIcon round />
                                            </InstapaperShareButton>
                                        </li>
                                        <li>
                                            <HatenaShareButton
                                                url={shareUrl.share}
                                                title={shareUrl.title}
                                                windowWidth={660}
                                                windowHeight={460}
                                                className="Demo__some-network__share-button"
                                            >
                                                <HatenaIcon round />
                                            </HatenaShareButton>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                }

            </Modal.Body>
        </Modal>
    )
}

export default ModalPopup