import { setAlert } from "./AlertAction";
import { commonAxios } from "../Global/CommonAxios";


export const fetchRebrandData = () => (dispatch, getState) => {
    commonAxios("fetch-wl-rebranding", {}, dispatch, getState().auth.token)
        .then(res => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch({ type: 'ADD_REBRAND', payload: res.data });
            }
        }).catch(error => {
            console.log(error)
        })
}


export const addRebrandData = (data, setLoader, setFileData, setName) => (dispatch, getState) => {
    commonAxios("wl-rebranding", data, dispatch, getState().auth.token)
        .then(res => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch(fetchRebrandData());
                setFileData("")
                setName("")
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            console.log(error)
            setLoader(false);
        })
}

export const removeRebrandData = () => (dispatch) => {
    dispatch({ type: 'REMOVE_ADD_REBRAND' });
}