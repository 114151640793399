import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { VscGraphLine } from "react-icons/vsc";
import { HiLink, HiOutlineCurrencyDollar, HiOutlineReceiptRefund } from "react-icons/hi";
import { BsBox } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineUsergroupDelete } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa"
import { Link } from "react-router-dom";
import ViewBusinessNav from "../Business/ViewBusinessNav";

const BusinessAnalytics = () => {
    return (
        <>
            <TitleBar title="Coupon" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">


                        <ViewBusinessNav />

                        <div className="business-top">
                            <h2>Analytics</h2>
                        </div>
                        <div className="stat-box-wrap">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><VscGraphLine /></span>
                                            <h6>Gross</h6>
                                            <h2>120</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><HiLink /></span>
                                            <h6>Links</h6>
                                            <h2>2000</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><BsBox /></span>
                                            <h6>Products</h6>
                                            <h2>1000</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><AiOutlineUsergroupAdd /></span>
                                            <h6>Total Customers</h6>
                                            <h2>1000</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><HiOutlineCurrencyDollar /></span>
                                            <h6>Paid Customers </h6>
                                            <h2>1000</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><AiOutlineUsergroupDelete /></span>
                                            <h6>Unpaid Customers</h6>
                                            <h2>1000</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><HiOutlineReceiptRefund /></span>
                                            <h6>Refunds</h6>
                                            <h2>1000</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="business-top mt-5">
                            <h2>Graphs & Generate Report in PDF</h2>
                        </div>

                        <div className="stat-box-wrap">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="statt-box">
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BusinessAnalytics;