import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FiArrowRight, FiSearch } from "react-icons/fi"
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { Link } from "react-router-dom";
import { onFetchProducts } from "../../Actions/ProductAction";
import { useDispatch } from "react-redux";
import ProductRow from "./ProductRow";

const Product = () => {
    const dispatch = useDispatch()
    const [product, setProduct] = useState([])
    const [loader, setLoader] = useState(false)
    const [search, setSearch] = useState({
        key: "",
        filterVal: ""
    })

    const [state, setState] = useState({
        name: false,
        bId: false,
        mode: false,
        date: false,
        price: false
    })
    const fetchProducts = () => {
        setLoader(true)
        dispatch(onFetchProducts(setProduct, setLoader))
    }

    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearch({
            ...search,
            [name]: value
        })
    }

    const handleSort = (type) => {
        let data = [...product]
        if (type === "name") {
            if (state.name) {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    name: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    name: true
                })
            }
        }
        if (type === "bId") {
            if (state.bId) {
                data.sort((a, b) => {
                    if (a.id.toLowerCase() < b.id.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    bId: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.id.toLowerCase() > b.id.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    bId: true
                })
            }
        }
        if (type === "mode") {
            if (state.mode) {
                data.sort((a, b) => {
                    if (a.mode.toLowerCase() < b.mode.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    mode: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.mode.toLowerCase() > b.mode.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    mode: true
                })
            }

        }
        if (type === "price") {
            if (state.price) {
                data.sort((a, b) => {
                    if (a.price.toLowerCase() < b.price.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    price: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.price.toLowerCase() > b.price.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    price: true
                })
            }

        }
        if (type === "date") {
            if (state.date) {
                data.sort((a, b) => {
                    if (a.modified.toLowerCase() < b.modified.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    date: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.modified.toLowerCase() > b.modified.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    date: true
                })
            }

        }
        setProduct(data)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <>
            <TitleBar title="Business" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">
                        <ViewBusinessNav />
                        <div className="business-top">
                            <h2>Your Products</h2>
                            <Link className="demoLink mt-0" to="/create-product">Create Products <FiArrowRight /> </Link>
                        </div>
                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><FiSearch /></span>
                                    <input
                                        className="inpSearch withIcon"
                                        type="text"
                                        name="key"
                                        onChange={(e) => handleSearch(e)}
                                        placeholder="Search product name"
                                    />
                                </div>
                                <div className="business-search-inp ml-2">
                                    <select
                                        name="filterVal"
                                        className="inpSearch"
                                        onChange={(e) => handleSearch(e)}
                                    >
                                        <option value="">All</option>
                                        <option value="sandbox">Sandbox</option>
                                        <option value="live">Live</option>
                                    </select>
                                </div>
                            </div>

                            <div className="bListing">
                                <table className="table businessTable">
                                    <thead>
                                        <tr>
                                            <th width="100"></th>
                                            <th onClick={() => handleSort('name')} className='cursor-pointer'>Name</th>
                                            <th onClick={() => handleSort('bId')} className='cursor-pointer'>Product ID</th>
                                            <th onClick={() => handleSort('mode')} className='cursor-pointer'>Mode</th>
                                            <th onClick={() => handleSort('price')} className='cursor-pointer'>Price</th>
                                            <th onClick={() => handleSort('date')} className='cursor-pointer'>Last Update</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {product.length > 0 ?
                                            product.filter((item) => {
                                                return item.name.toLowerCase().includes(search.key.toLowerCase())
                                            }).filter((ele) => {
                                                if (search.filterVal === "") {
                                                    return ele
                                                }
                                                return ele.mode === search.filterVal
                                            }).map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ProductRow
                                                            curElem={curElem}
                                                            fetchProducts={fetchProducts}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""
                                        }
                                    </tbody>
                                </table>
                                {loader ?
                                    <div className="text-center my-4">
                                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                                    </div>
                                    : product.length === 0 ? <div className="text-center mt-4 text-capitalize">"No products created yet"</div> : ""
                                }
                                <div className="tablepage">
                                    <ul>
                                        <li><a href=""><BsChevronLeft /></a></li>
                                        <li><a className="active" href="">1</a></li>
                                        <li><a href="">2</a></li>
                                        <li><a href=""><BsChevronRight /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Product;