import React, { useState } from 'react'
import { BsCodeSlash, BsShare } from 'react-icons/bs'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { SiMaterialdesignicons } from 'react-icons/si'
import ModalPopup from './ModalPopup'

const ProductDropdownData = ({ curElem }) => {
    const [show, setShow] = useState(false)
    const [type, setType] = useState("")
    const [embed, setEmbed] = useState({
        header: '',
        headerStatus: false,
        checkout: '',
        checkoutStatus: false,
    })

    const handleShow = (val) => {
        setType(val)
        setEmbed({
            ...embed,
            header: `<script type="text/Javascript" id="kc_widget" src="https://app.fastpaid.com/front-end/widget.js?product_id=${curElem.id}"></script>`,
            checkout: `<div id="fastpaid_checkout_section"></div>`
        })
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <span onClick={() => handleShow("copy")}><BsCodeSlash /> Embed Code</span>
            <span onClick={() => handleShow("url")}><BsShare /> Share</span>
            <a href="#/action-3"><HiOutlineDocumentDuplicate /> Duplicate</a>
            <a href="#/action-3"><SiMaterialdesignicons /> Page Design</a>

            <ModalPopup
                show={show}
                handleClose={handleClose}
                embed={embed}
                setEmbed={setEmbed}
                type={type}
            />
        </>
    )
}

export default ProductDropdownData