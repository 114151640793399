import React, { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai"
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { onSubmitBusiness, onUploadBusinessLogo } from "../../Actions/BusinessAction";
import OpeningHours from "./OpeningHours";

const BusinessGeneral = ({ data }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        loadLoader: false,
        saveLoader: false
    })
    const [state, setState] = useState({
        name: "",
        description: "",
        address: "",
        phone: "",
        timezone: "",
        timeFormat: 24,
        openingHour: [
            {
                day: "Sunday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            },
            {
                day: "Monday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            },
            {
                day: "Tuesday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            },
            {
                day: "Wednesday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            },
            {
                day: "Thursday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            },
            {
                day: "Friday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            },
            {
                day: "Saturday",
                enable: false,
                fromTime: "",
                fromMeridiem: "",
                toTime: "",
                toMeridiem: ""
            }
        ],
        logo: {
            url: "",
            name: ""
        }
    })

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "timeFormat") {
            setState({
                ...state,
                [name]: checked ? 12 : 24
            })
        }
        else {
            setState({
                ...state,
                [name]: value
            })
        }
    }

    const onUploadLogo = (e) => {
        let imageData = e.target.files[0]
        let allowedType = ["image/png", "image/jpg", "image/jpeg"]
        if (imageData) {
            if (allowedType.includes(imageData.type)) {
                if (imageData.size < 5000001) {
                    const formData = new FormData()
                    formData.append("upload_type", 'logo')
                    formData.append("file", imageData)
                    setLoader({
                        ...state,
                        loadLoader: true
                    })
                    dispatch(onUploadBusinessLogo(formData, state, setState, loader, setLoader))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for image is 5MB.',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a valid file type.',
                })
            }
        }
    }

    const onSubmit = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        let ep
        let obj
        if (data) {
            const val = { ...state }
            delete val.created
            delete val.modefied
            delete val.status
            delete val.userId
            delete val.design
            delete val.isdeleted

            obj = val
            ep = "update-business"
        }
        else {
            obj = { ...state }
            ep = "create-business"
        }
        dispatch(onSubmitBusiness(ep, obj, loader, setLoader))
    }

    useEffect(() => {
        if (data) {
            setState(data)
        }
    }, [data])

    return (
        <>
            <div className="productTabs-main"> 
                <div className="row">
                    <div className="col-lg-6">
                        <div className="inpLabelWrap mt-0">
                           <span className="labelTxt">Name</span>
                            <div className="inpLabel">
                                <label htmlFor="">Name:</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Your Name Here"
                                    type="text"
                                    name="name"
                                    value={state.name}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap mt-0">
                            <span className="labelTxt">Phone</span>
                            <div className="inpLabel">
                                <label htmlFor="">Phone:</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Your Phone Here"
                                    type="text"
                                    name="phone"
                                    value={state.phone}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="inpLabelWrap">
                    <span className="labelTxt">Email</span>
                    <div className="inpLabel">
                        <label htmlFor="">Email:</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Your Email Here"
                            type="text"
                            name="name"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Description</span>
                            <div className="inpLabel">
                                <label htmlFor="description">Description:</label>
                                <textarea
                                    className="inpLabel-inp"
                                    name="description"
                                    id="description"
                                    placeholder="Add A Description"
                                    value={state.description}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Address</span>
                            <div className="inpLabel">
                                <label htmlFor="">Address:</label>
                                <textarea
                                    className="inpLabel-inp"
                                    name="address"
                                    placeholder="Your Address Here"
                                    value={state.address}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="openHr">
                    <div className="openHr-left">
                        <label htmlFor="">Opening hours:</label>
                    </div>
                    <div className="openHr-right">
                        {state.openingHour.map((curElem, index) => {

                            return (
                                <React.Fragment key={index}>
                                    <OpeningHours
                                        curElem={curElem}
                                        index={index}
                                        state={state}
                                        setState={setState}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                
                <div className="inpLabelWrap">
                    <span className="labelTxt">Timezone</span>
                    <div className="inpLabel">
                        <label htmlFor="">Timezone:</label>
                        <select
                            className="inpLabel-inp"
                            name="timezone"
                            value={state.timezone}
                            onChange={(e) => handleChange(e)}
                        >
                            <option value="">Select Timezone</option>
                            <option value="Timezone-1">Timezone-1</option>
                            <option value="Timezone-2">Timezone-2</option>
                            <option value="Timezone-3">Timezone-3</option>
                        </select>
                    </div>
                </div>   
                   
                    
                <div className="row">
                    <div className="col-lg-6">
                        <div className="locateBox full">
                            <div className="pr-3">Locale default</div>
                            <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="locateBox full">
                            <div className="pr-3">24-hour format (13:00)</div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name="timeFormat"
                                    checked={+state.timeFormat === 12 ? true : false}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                    
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Business URL</span>
                            <div className="inpLabel">
                                <label htmlFor="">Business URL:</label>
                                <input className="inpLabel-inp" placeholder="invoicereel.com" type="text" />
                                <span className="labelStat">.fastpaid.io</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Add a Domain</span>
                            <div className="inpLabel">
                                <label htmlFor="">Addon Domain:</label>
                                <input className="inpLabel-inp" placeholder="Enter Addon Domain" type="text" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="inpLabel">
                            <label htmlFor="">Upload Logo</label>
                            <div className="style-upload-box">
                                <input
                                    className="custom-file-input"
                                    type="file"
                                    onChange={(e) => onUploadLogo(e)}
                                />
                                <div>
                                    {loader.loadLoader ?
                                        <>
                                            <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                                        </>
                                        :
                                        <>
                                            <AiOutlineCloudUpload />
                                            <h6>{state.logo.name !== "" ? state.logo.name : "Upload Your Logo"}</h6>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="btn-sec text-right">
                    <button className="demoLink" onClick={() => onSubmit()}>{loader.saveLoader ? <>{data ? "Updating" : "Saving"} <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "15px" }} /></> : data ? "Update" : "Create Business"}</button>
                </div>

            </div>
        </>
    )
}

export default BusinessGeneral;