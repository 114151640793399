import React, { useState } from 'react'

const BusinessTableHeader = ({ businessData, setBusinessData }) => {
    const [state, setState] = useState({
        name: false,
        bId: false,
        mode: false,
        date: false,
        price: false
    })
    const handleSort = (type) => {
        let data = [...businessData]
        if (type === "name") {
            if (state.name) {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    name: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    name: true
                })
            }
        }
        if (type === "bId") {
            if (state.bId) {
                data.sort((a, b) => {
                    if (a.id.toLowerCase() < b.id.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    bId: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.id.toLowerCase() > b.id.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    bId: true
                })
            }
        }
        if (type === "mode") {
            if (state.mode) {
                data.sort((a, b) => {
                    if (a.mode.toLowerCase() < b.mode.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    mode: false
                })
            } else {
                data.sort((a, b) => {
                    if (a.mode.toLowerCase() > b.mode.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    mode: true
                })
            }

        }
        // if (type === "price") {
        //     if (state.price) {
        //         data.sort((a, b) => {
        //             if (a.price.toLowerCase() > b.price.toLowerCase()) {
        //                 return 1
        //             }
        //             else {
        //                 return -1
        //             }
        //         })
        //         setState({
        //             ...state,
        //             price: false
        //         })
        //     } else {
        //         data.sort((a, b) => {
        //             if (a.price.toLowerCase() < b.price.toLowerCase()) {
        //                 return 1
        //             }
        //             else {
        //                 return -1
        //             }
        //         })
        //         setState({
        //             ...state,
        //             price: true
        //         })
        //     }
        // }
        if (type === "date") {
            if (state.date) {
                data.sort((a, b) => {
                    if (a.modified.toLowerCase() < b.modified.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    date: false
                })
            }
            else {
                data.sort((a, b) => {
                    if (a.modified.toLowerCase() > b.modified.toLowerCase()) {
                        return 1
                    }
                    else {
                        return -1
                    }
                })
                setState({
                    ...state,
                    date: true
                })
            }
        }
        setBusinessData(data)
    }
    return (
        <tr>
            <th width="150"></th>
            <th onClick={() => handleSort('name')} className='cursor-pointer'>Name</th>
            <th onClick={() => handleSort('bId')} className='cursor-pointer'>Business ID</th>
            <th onClick={() => handleSort('mode')} className='cursor-pointer'>Mode</th>
            <th onClick={() => handleSort('price')} className='cursor-pointer'>Products</th>
            <th onClick={() => handleSort('date')} className='cursor-pointer'>Last Update</th>
            <th></th>
        </tr>
    )
}

export default BusinessTableHeader