import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ProductDesign from "./ProductDesign";
import ProductGeneral from "./ProductGeneral";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { onGetProduct } from "../../Actions/ProductAction";
import { useDispatch } from "react-redux";

const CreateProduct = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const id = queryString.parse(location.search).id
    const [state, setState] = useState({
        name: "shoes",
        bid: 1,
        description: "this is my product",
        price: "10",
        currency: "inr",
        frequency: "test",
        remaining: "remaining",
        period: "period",
        numberPayment: 1,
        isPhone: "yes",
        isAddon: "yes",
        legal: {
            enable: false,
            url: ""
        },
        isExist: "yes",
        ExistData: "yes",
        thankyouPage: "thanku",
        payment: "online",
        autoresponder: "auto",
        slug: "https:payment.com",
        tag: "product",
        staff: "yes"
    })

    const fetchProductData = () => {
        let data = {
            id: id
        }
        dispatch(onGetProduct(data, setState, navigate))
    }

    useEffect(() => {
        if (id) {
            fetchProductData()
        }
    }, [id])
    return (
        <>
            <TitleBar title="Business" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">
                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                                <Nav className="navSet nav-fill">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">General</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">Design</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="productTabs">
                                    <Tab.Pane eventKey="tab1">
                                        <ProductGeneral
                                            setState={setState}
                                            state={state}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <ProductDesign />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CreateProduct;