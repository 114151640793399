import React from 'react'
const OpeningHours = ({ curElem, index, state, setState }) => {

    const handleTime = (e, isFrom, isTo) => {
        const { checked, name, value } = e.target
        let data = { ...state }
        if (name === "enable") {
            data.openingHour[index].enable = checked
        }
        else if (name === "meridiem") {
            if (isFrom) {
                data.openingHour[index].fromMeridiem = value
            }
            if (isTo) {
                data.openingHour[index].toMeridiem = value
            }

            // if (isFrom) {
            //     if (data.openingHour[index].from !== "") {
            //         if (value === "AM") {
            //             if (data.openingHour[index].from.includes("PM")) {
            //                 data.openingHour[index].from = data.openingHour[index].from.replace("PM", value)
            //             }
            //             else if (!data.openingHour[index].from.includes("PM") && !data.openingHour[index].from.includes("AM")) {
            //                 data.openingHour[index].from = data.openingHour[index].from.concat('|' + value)
            //             }
            //         } else {
            //             if (data.openingHour[index].from.includes("AM")) {
            //                 data.openingHour[index].from = data.openingHour[index].from.replace("AM", value)
            //             }
            //             else if (!data.openingHour[index].from.includes("PM") && !data.openingHour[index].from.includes("AM")) {
            //                 data.openingHour[index].from = data.openingHour[index].from.concat('|' + value)
            //             }
            //         }
            //     }
            // }
            // if (isTo) {
            //     if (data.openingHour[index].to !== "") {
            //         if (value === "AM") {
            //             if (data.openingHour[index].to.includes("PM")) {
            //                 data.openingHour[index].to = data.openingHour[index].to.replace("PM", value)
            //             }
            //             else if (!data.openingHour[index].to.includes("PM") && !data.openingHour[index].to.includes("AM")) {
            //                 data.openingHour[index].to = data.openingHour[index].to.concat(value)
            //             }
            //         } else {
            //             if (data.openingHour[index].to.includes("AM")) {
            //                 data.openingHour[index].to = data.openingHour[index].to.replace("AM", value)
            //             }
            //             else if (!data.openingHour[index].to.includes("PM") && !data.openingHour[index].to.includes("AM")) {
            //                 data.openingHour[index].to = data.openingHour[index].to.concat(value)
            //             }
            //         }
            //     }
            // }
        }
        else {
            if (isFrom) {
                data.openingHour[index].fromTime = value
            }
            if (isTo) {
                data.openingHour[index].toTime = value
            }
        }
        setState(data)
    }

    return (
        <div className="openHr-single">
            <div className="openHr-single-left">
                <div>{curElem.day}</div>
                <div>
                    <label className="switch flex">
                        <input
                            type="checkbox"
                            name="enable"
                            checked={curElem.enable}
                            onChange={(e) => handleTime(e, index)}
                        />
                        <span className="slider round"></span>
                        <span className="slClose">Closed</span>
                        <span className="slOpen">Open</span>
                    </label>
                </div>
            </div>
            {curElem.enable ?
                <div className="openHr-single-right">
                    <div className="timeFrom">
                        <div>FROM</div>
                        <div
                            className="timeFrom-box"
                        >
                            <select
                                name="from"
                                value={curElem.fromTime}
                                onChange={(e) => handleTime(e, true, false)}
                            >
                                <option value="">Select Time</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                            </select>

                            <select
                                name="meridiem"
                                value={curElem.fromMeridiem}
                                onChange={(e) => handleTime(e, true, false)}
                            >
                                <option value="">Select Meridiem</option>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                    </div>
                    <div className="timeFrom">
                        <div>TO</div>
                        <div
                            className="timeFrom-box"
                        >
                            <select
                                name="to"
                                value={curElem.toTime}
                                onChange={(e) => handleTime(e, false, true)}
                            >
                                <option value="">Select Time</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                            </select>
                            <select
                                name="meridiem"
                                value={curElem.toMeridiem}
                                onChange={(e) => handleTime(e, false, true)}
                            >
                                <option value="">Select Meridiem</option>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                    </div>
                </div> : ""}
        </div>
    )
}

export default OpeningHours