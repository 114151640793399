import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import BusinessGeneral from "../CreateBusiness/BusinessGeneral";
import BusinessDesign from "../CreateBusiness/BusinessDesign";
import BusinessIntregration from "../CreateBusiness/BusinessIntregration";
import { Nav, Tab } from 'react-bootstrap';
import ViewBusinessNav from "./ViewBusinessNav";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string'
import { useDispatch } from "react-redux";
import { onGetBusiness } from "../../Actions/BusinessAction";

const ViewBusiness = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const id = queryString.parse(location.search).id;

    const [state, setState] = useState(false)
    const fetchBusiness = () => {
        let data = {
            id: id
        }
        dispatch(onGetBusiness(data, setState, navigate))
    }
    useEffect(() => {
        if (id) {
            fetchBusiness()
        }
    }, [id])

    return (
        <>
            <TitleBar title="Create Business" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">
                        <ViewBusinessNav />
                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                                <Nav className="navSet nav-fill">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">General</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">Design</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">Integration</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="productTabs">
                                    <Tab.Pane eventKey="tab1">
                                        <BusinessGeneral
                                            data={state}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <BusinessDesign />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        <BusinessIntregration />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ViewBusiness;