import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

import { FiArrowRight, FiSearch } from "react-icons/fi"

const Staff = () => {
    return (
        <>
        <TitleBar title="Staff" />
        <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">
                        <div className="business-top">
                            <h2>Your Staffs</h2>
                            <a className="demoLink mt-0" href="/create-staff">Create Staff <FiArrowRight/> </a>
                        </div>
                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><FiSearch/></span>
                                    <input className="inpSearch withIcon" type="text" placeholder="Search business name" />
                                </div>
                                <div className="business-search-inp ml-2">
                                    <select name="" id="" className="inpSearch">
                                        <option value="">All</option>
                                        <option value="">Option - 2</option>
                                        <option value="">Option - 3</option>
                                    </select>
                                </div>
                            </div>

                            <div className="bListing">
                                <table className="table businessTable">
                                    <tr>
                                        <th width="150"></th>
                                        <th>Name</th>
                                        <th>Staff ID</th>
                                        <th>Mode</th>
                                        <th>Price</th>
                                        <th>Last Update</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td>Mode</td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
        </>
    )
}

export default Staff;