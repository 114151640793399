import React from "react";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import { useSelector } from "react-redux";
import sonorityIcon from "../../images/logo-sonority.png";
import videoIcon from "../../images/videoreel.svg";
import IntegrationHead from "../Integration/IntegrationHead";
import ConnectFacebook from "../Integration/ConnectFacebook";
import ConnectYoutube from "../Integration/ConnectYoutube";
import ConnectReelapps from "../Integration/ConnectReelapps";

const Integration = () => {

    const socialData = useSelector(state => state.social);
    return (
        <>
            <div className="siteWrapn">
                <IntegrationHead socialData={socialData} />
                <h2 className="titleBdr">My Connection</h2>
                <ConnectFacebook data={socialData.facebook} />
                <ConnectYoutube data={socialData.youtube} />
                <ConnectReelapps icon={syvidIcon} name="Syvid" type="syvid" data={socialData.syvid} />
                <ConnectReelapps icon={scriptIcon} name="ScriptReel" type="scriptreel" data={socialData.scriptreel} />
                <ConnectReelapps icon={trimIcon} name="TrimReel" type="trimreel" data={socialData.trimreel} />
                <ConnectReelapps icon={liveIcon} name="LiveReel" type="livereel" data={socialData.livereel} />
                <ConnectReelapps icon={sonorityIcon} name="Sonority" type="sonority" data={socialData.sonority} />
                <ConnectReelapps icon={videoIcon} name="VideoReel" type="videoreel" data={socialData.videoreel} />
            </div>

        </>
    )
}

export default Integration;