import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

import { FiArrowRight } from "react-icons/fi"
import {FaBuilding} from "react-icons/fa"

const CreateClients = () => {
    return (
        <>
        <TitleBar title="Create Clients" />
        <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">

                       <div className="productNav nav mb-4">
                          <div className="nav-item"><a className="nav-link" href=""><FaBuilding/></a></div>
                          <div className="nav-item"><a className="nav-link" href="/product">Products</a></div>
                          <div className="nav-item"><a className="nav-link" href="/business-analytics">Analytics</a></div>
                          <div className="nav-item"><a className="nav-link active" href="/clients">Clients</a></div>
                          <div className="nav-item"><a className="nav-link" href="/coupon">Coupons</a></div>
                          <div className="nav-item"><a className="nav-link" href="/reminders">Reminders</a></div>
                       </div>

                        <div className="business-top">
                            <h2>Your Clients</h2>
                            <a className="demoLink mt-0" href="">Create Client <FiArrowRight/> </a>
                        </div>
                        <div className="business-list">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Name</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Name</label>
                                            <input className="inpLabel-inp" placeholder="Add Name" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Email</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Email</label>
                                            <input className="inpLabel-inp" placeholder="Add Email" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Phone</span>
                                <div className="inpLabel">
                                    <label htmlFor="">Phone</label>
                                    <input className="inpLabel-inp" placeholder="Add Phone" type="text" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap">
                                       <span className="labelTxt">Business</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Business</label>
                                            <select name="" id="" className="inpLabel-inp">
                                                <option value="">Business</option>
                                                <option value="">Business</option>
                                                <option value="">Business</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap">
                                       <span className="labelTxt">Products</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Products</label>
                                            <select name="" id="" className="inpLabel-inp">
                                                <option value="">Products</option>
                                                <option value="">Products</option>
                                                <option value="">Products</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-sec text-right mt-4">
                               <button className="demoLink">Create Client</button>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </section>
        <Footer />
        </>
    )
}

export default CreateClients;