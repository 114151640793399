import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FiArrowRight, FiSearch } from "react-icons/fi"
import ViewBusinessNav from "../Business/ViewBusinessNav";

import Dropdown from 'react-bootstrap/Dropdown';

import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi"

import dotImg from "../../images/dots.png";

import { BsCodeSlash, BsShare } from 'react-icons/bs'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { SiMaterialdesignicons } from 'react-icons/si'

const Coupons = () => {
    return (
        <>
            <TitleBar title="Coupon" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">

                        <ViewBusinessNav />

                        <div className="business-top">
                            <h2>Your Coupon</h2>
                            <a className="demoLink mt-0" href="/create-coupon">Create Coupon <FiArrowRight /> </a>
                        </div>
                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><FiSearch /></span>
                                    <input className="inpSearch withIcon" type="text" placeholder="Search business name" />
                                </div>
                                <div className="business-search-inp ml-2">
                                    <select name="" id="" className="inpSearch">
                                        <option value="">All</option>
                                        <option value="">Option - 2</option>
                                        <option value="">Option - 3</option>
                                    </select>
                                </div>
                            </div>

                            <div className="bListing">
                                <table className="table businessTable">
                                    <thead>
                                        <tr>
                                            <th width="150"></th>
                                            <th>Name</th>
                                            <th>Staff ID</th>
                                            <th>Mode</th>
                                            <th>Price</th>
                                            <th>Last Update</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode live">Live</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode live">Live</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="proThumb">
                                                <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                                            </div>
                                        </td>
                                        <td>Staff Name</td>
                                        <td>ID-25624</td>
                                        <td><span className="pMode sandbox">SandBox</span></td>
                                        <td>$200</td>
                                        <td>10.12.2022</td>
                                        <td>
                                        <Dropdown className="tableDrop">
                                        <Dropdown.Toggle variant="n">
                                            <img src={dotImg} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <a href=""><BsCodeSlash /> Embed Code</a>
                                            <a href=""><BsShare /> Share</a>
                                            <a href=""><HiOutlineDocumentDuplicate /> Duplicate</a>
                                            <a href=""><SiMaterialdesignicons /> Page Design</a>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>

                                    </tbody>
                                    
                                </table>

                                <div className="tablepage">
                                    <ul>
                                        <li><a href=""><BsChevronLeft /></a></li>
                                        <li><a className="active" href="">1</a></li>
                                        <li><a href="">2</a></li>
                                        <li><a href=""><BsChevronRight /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Coupons;