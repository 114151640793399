import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandReducer } from "./RebrandReducer";
import { SocialReducer } from "./SocialReducer";
import { SupportReducer } from "./SupportReducer";


const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    support: SupportReducer,
    rebrand: RebrandReducer,
    social: SocialReducer,
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;