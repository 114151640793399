import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';

const ContentModal = ({ show, handleClose }) => {
    const [loader, setLoader] = useState(false)
    const [contentName, setContentName] = useState({
        text: "",
        len: 0
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        // let data = { "campaignName": contentName.text }
        setLoader(true)
        // dispatch(onCreateName(data, history, setLoader))
    }

    const handleChange = (val) => {
        setContentName({
            ...contentName,
            text: val,
            len: val.length
        })
    }
    return (
        <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt=""/></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <div className="inpField">

                        <label className='d-none'>Enter Project Name <span style={{ float: "right", fontWeight: "normal" }}>{contentName.len} / 100</span></label>

                        <div className='row'>
                            <div className='col-lg-6'>
                                <label htmlFor="">Business Name</label>
                                <select name="" id="" className="inpField-inp text-light my-2">
                                    <option value="">Business Name</option>
                                    <option value="">Business Name</option>
                                    <option value="">Business Name</option>
                                </select>
                                {/* <input
                                    type="text"
                                    className="inpField-inp text-light my-2"
                                    placeholder="Project Name"
                                    onChange={(e) => handleChange(e.target.value)}
                                    required
                                    maxLength={100}
                                /> */}
                            </div>
                            <div className='col-lg-6'>
                                <label htmlFor="">Product Name</label>
                                <select name="" id="" className="inpField-inp text-light my-2">
                                    <option value="">Product Name</option>
                                    <option value="">Product Name</option>
                                    <option value="">Product Name</option>
                                </select>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <label htmlFor="">Customer Name</label>
                            <select name="" id="" className="inpField-inp text-light my-2">
                                <option value="">Customer Name</option>
                                <option value="">Customer Name</option>
                                <option value="">Customer Name</option>
                            </select>
                        </div>
                        
                    </div>
                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">Generate Invoice {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ContentModal