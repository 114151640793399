import './App.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Components/Auth/Login';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import MasterLogin from './Components/Auth/MasterLogin';
import { PrivateRoute } from './Components/PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/User/Profile';
import Privacy from './Components/User/Privacy';
import AccountManagement from './Components/User/AccountManagement';
import Reseller from './Components/User/Reseller';
import Upgrades from './Components/User/Upgrades';
import HelpSupport from './Components/Support/HelpSupport';
import Training from './Components/Support/Training';
import TrainingArticles from './Components/Support/TrainingArticles';
import Integration from './Components/Integration/Integration';
import Business from './Components/Business/Business';
import Product from './Components/Product/Product';
import CreateProduct from './Components/Product/CreateProduct';
import Staff from './Components/Staff/Staff';
import CreateStaff from './Components/Staff/CreateStaff';
import Coupons from './Components/Coupons/Coupons';
import CreateCoupon from './Components/Coupons/CreateCoupon';
import BusinessAnalytics from './Components/BusinessAnalytics/BusinessAnalytics';
import Reminders from './Components/Reminders/Reminders';
import CreateReminders from './Components/Reminders/CreateReminders';
import Clients from './Components/Clients/Clients';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Actions/AuthAction';
import { removeAlert } from './Actions/AlertAction';
import Alert from './Components/Alert';
import CreateClients from './Components/Clients/CreateClients';
import ViewBusiness from './Components/Business/ViewBusiness';
import CreateBusiness from './Components/CreateBusiness/CreateBusiness';

function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <div className="App">
      <Alert />
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/master-login' element={<MasterLogin />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/reseller" element={<PrivateRoute><Reseller /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/help-and-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /></PrivateRoute>} />
            <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />

            <Route exact path="/business" element={<PrivateRoute><Business /></PrivateRoute>} />
            <Route exact path="/view-business" element={<PrivateRoute><ViewBusiness /></PrivateRoute>} />
            <Route exact path="/create-business" element={<PrivateRoute><CreateBusiness /></PrivateRoute>} />
            <Route exact path="/product" element={<PrivateRoute><Product /></PrivateRoute>} />
            <Route exact path="/create-product" element={<PrivateRoute><CreateProduct /></PrivateRoute>} />
            <Route exact path="/staff" element={<PrivateRoute><Staff /></PrivateRoute>} />
            <Route exact path="/create-staff" element={<PrivateRoute><CreateStaff /></PrivateRoute>} />
            <Route exact path="/coupon" element={<PrivateRoute><Coupons /></PrivateRoute>} />
            <Route exact path="/create-coupon" element={<PrivateRoute><CreateCoupon /></PrivateRoute>} />
            <Route exact path="/business-analytics" element={<PrivateRoute><BusinessAnalytics /></PrivateRoute>} />
            <Route exact path="/reminders" element={<PrivateRoute><Reminders /></PrivateRoute>} />
            <Route exact path="/create-reminders" element={<PrivateRoute><CreateReminders /></PrivateRoute>} />
            <Route exact path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
            <Route exact path="/create-clients" element={<PrivateRoute><CreateClients /></PrivateRoute>} />
            
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
