const initialState = {
    message: "",
    type: ""
}

export const AlertReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_ALERT':
            return { ...payload }
        case 'REMOVE_ALERT':
            return { message: "", type: "" }
        default:
            return state
    }
}