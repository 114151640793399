import React, { useState } from 'react'

const ProductGeneral = ({ state, setState }) => {


    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "legal") {
            setState({
                ...state,
                legal: {
                    ...state.legal,
                    url: value
                }
            })
        }
        else {
            setState({
                ...state,
                [name]: value
            })
        }
    }
    const handleChecked = (e) => {
        const { name, checked } = e.target
        if (name === "legal") {
            setState({
                ...state,
                legal: {
                    ...state.legal,
                    enable: checked
                }
            })
        } else {
            setState({
                ...state,
                [name]: checked
            })
        }

    }

    return (
        <div className="productTabs-main">
            <div className="inpLabelWrap mt-0">
                <span className="labelTxt">Give your product a name.</span>
                <div className="inpLabel mt-0">
                    <label htmlFor="name">Name:</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Your Name Here"
                        type="text"
                        name='name'
                        value={state.name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Give your product Description.</span>
                <div className="inpLabel">
                    <label htmlFor="description">Description:</label>
                    <textarea
                        type="text"
                        className="inpLabel-inp"
                        placeholder="Add A Description"
                        name="description"
                        value={state.description}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="openHr">
                <div className="openHr-left">
                    <label htmlFor=""><strong>Payment Settings:</strong></label>
                </div>
                <div className="openHr-right">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="inpLabelWrap mt-0">
                                <span className="labelTxt">Give your product a price.</span>
                                <div className="inpLabel">
                                    <label htmlFor="price">Price</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="10"
                                        type="text"
                                        value={state.price}
                                        name="price"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="inpLabelWrap mt-0">
                                <span className="labelTxt">Payment Frequency</span>
                                <div className="inpLabel">
                                    <label htmlFor="frequency">Payment Frequency</label>
                                    <select
                                        className="inpLabel-inp square"
                                        name="frequency"
                                        value={state.frequency}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option value="test">Payment Frequency - 1</option>
                                        <option value="test">Payment Frequency - 2</option>
                                        <option value="test">Payment Frequency - 3</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Recurring Frequency</span>
                                <div className="inpLabel">
                                    <label htmlFor="remaining">Recurring Frequency</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="10"
                                        type="text"
                                        name='remaining'
                                        value={state.remaining}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Recurring Period</span>
                                <div className="inpLabel">
                                    <label htmlFor="period">Recurring Period</label>
                                    <select
                                        className="inpLabel-inp square"
                                        name="period"
                                        value={state.period}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option value="period">Recurring Period - 1</option>
                                        <option value="period">Recurring Period - 2</option>
                                        <option value="period">Recurring Period - 3</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Of Payments</span>
                                <div className="inpLabel">
                                    <label htmlFor="numberPayment">Of Payments</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="10"
                                        type="text"
                                        name='numberPayment'
                                        value={state.numberPayment}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inpLabel d-flex justify-content-start align-items-center">
                <label htmlFor="">Checkout Fields:</label>
                <div className="d-flex">
                    <div className="locateBox mt-0">
                        <div className="pr-3">Collect Phone</div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                name='isPhone'
                                checked={state.isPhone}
                                onChange={(e) => handleChecked(e)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="locateBox mt-0 ml-3">
                        <div className="pr-3">Collect Address</div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                name='isAddon'
                                checked={state.isAddon}
                                onChange={(e) => handleChecked(e)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>


            <div className="inpLabelWrap">
                <span className="labelTxt">Tracking</span>
                <div className="inpLabel">
                    <label htmlFor="">Tracking</label>
                    <textarea className="inpLabel-inp" name="" id="" placeholder="Tracking"></textarea>
                </div>
            </div>



            <div className="inpLabel d-flex justify-content-between line">
                <label htmlFor="">Legal Checkbox</label>
                <div className="d-flex">
                    <label className="switch">
                        <input
                            type="checkbox"
                            name='legal'
                            checked={state.legal.enable}
                            onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
            {state.legal.enable ?
                <div className="inpLabel">
                    <input
                        className="inpLabel-inp"
                        type="text"
                        placeholder="URL"
                        value={state.legal.url}
                        onChange={(e) => handleChange(e)}
                    />
                </div> : ""}
            <div className="inpLabel d-flex justify-content-between line">
                <label htmlFor="">Exit intent Popup</label>
                <div className="d-flex">
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>

            <div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Title</span>
                            <div className="inpLabel">
                                <label htmlFor="">Title</label>
                                <input className="inpLabel-inp" placeholder="Add Title" type="text" />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Body</span>
                            <div className="inpLabel">
                                <label htmlFor="">Body</label>
                                <input className="inpLabel-inp" placeholder="Add A Description" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Button</span>
                            <div className="inpLabel">
                                <label htmlFor="">Button</label>
                                <input className="inpLabel-inp" placeholder="Continue" type="text" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tempList">
                    <ul>
                        <li>
                            <div className="tempList-single">
                                <div className="tempList-img">
                                    <img src="https://images.pexels.com/photos/14844589/pexels-photo-14844589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tempList-single">
                                <div className="tempList-img">
                                    <img src="https://images.pexels.com/photos/14844589/pexels-photo-14844589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tempList-single">
                                <div className="tempList-img">
                                    <img src="https://images.pexels.com/photos/14844589/pexels-photo-14844589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tempList-single">
                                <div className="tempList-img">
                                    <img src="https://images.pexels.com/photos/14844589/pexels-photo-14844589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tempList-single">
                                <div className="tempList-img">
                                    <img src="https://images.pexels.com/photos/14844589/pexels-photo-14844589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tempList-single">
                                <div className="tempList-img">
                                    <img src="https://images.pexels.com/photos/14844589/pexels-photo-14844589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="inpLabelWrap">
                    <span className="labelTxt">Product Type</span>
                    <div className="inpLabel">
                        <label htmlFor="">Product Type</label>
                        <select name="" id="" className="inpLabel-inp">
                            <option value="">Services</option>
                            <option value="">Physical</option>
                            <option value="">Digital</option>
                        </select>
                    </div>
                </div>

                <div className="inpLabel d-flex justify-content-start">
                    <label htmlFor="">Office hours same as business</label>
                    <div className="d-flex">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                <div className="openHr">
                    <div className="openHr-left">
                        <label htmlFor="">Opening hours:</label>
                    </div>
                    <div className="openHr-right">
                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Sunday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Monday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Tuesday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Wednesday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Thursday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Friday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="openHr-single">
                            <div className="openHr-single-left">
                                <div>Saturday</div>
                                <div>
                                    <label className="switch flex">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                        <span className="slClose">Closed</span>
                                        <span className="slOpen">Open</span>
                                    </label>
                                </div>
                            </div>
                            <div className="openHr-single-right">
                                <div className="timeFrom">
                                    <div>FROM</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="timeFrom">
                                    <div>TO</div>
                                    <div className="timeFrom-box">
                                        <select name="" id="">
                                            <option value="">1:00</option>
                                            <option value="">2:00</option>
                                            <option value="">3:00</option>
                                            <option value="">4:00</option>
                                            <option value="">5:00</option>
                                            <option value="">6:00</option>
                                            <option value="">7:00</option>
                                            <option value="">8:00</option>
                                            <option value="">9:00</option>
                                            <option value="">10:00</option>
                                            <option value="">11:00</option>
                                            <option value="">12:00</option>
                                        </select>
                                        <select name="" id="">
                                            <option value="">AM</option>
                                            <option value="">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Thankyou Page URL</span>
                            <div className="inpLabel">
                                <label htmlFor="">Thankyou Page URL</label>
                                <input type="text" className="inpLabel-inp" placeholder="Thankyou Page URL" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Payment Processors</span>
                            <div className="inpLabel">
                                <label htmlFor="">Payment Processors</label>
                                <select name="" id="" className="inpLabel-inp">
                                    <option value="">Payment Processors-1</option>
                                    <option value="">Payment Processors-2</option>
                                    <option value="">Payment Processors-3</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">AutoResponder</span>
                            <div className="inpLabel">
                                <label htmlFor="">AutoResponder</label>
                                <select name="" id="" className="inpLabel-inp">
                                    <option value="">AutoResponder-1</option>
                                    <option value="">AutoResponder-2</option>
                                    <option value="">AutoResponder-3</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">URL Short</span>
                            <div className="inpLabel">
                                <label htmlFor="">URL Short</label>
                                <input type="text" className="inpLabel-inp" placeholder="URL Short" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Tag</span>
                            <div className="inpLabel">
                                <label htmlFor="">Tag</label>
                                <input type="text" className="inpLabel-inp" placeholder="Tag" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Staff</span>
                            <div className="inpLabel">
                                <label htmlFor="">Staff</label>
                                <input type="text" className="inpLabel-inp" placeholder="Staff" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="inpLabel d-flex justify-content-start">
                    <label htmlFor="">Product Status</label>
                    <div className="d-flex">
                        <label className="switch flex">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            <span className="slClose">Deactivate</span>
                            <span className="slOpen">Active</span>
                        </label>
                    </div>
                </div>

                <div className="btn-sec text-right">
                    <button className="demoLink">Save</button>
                </div>

            </div>
        </div>
    )
}

export default ProductGeneral