import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onUploadBusinessLogo = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    logo: {
                        ...state.logo,
                        url: res.data.path,
                        name: res.data.name
                    }
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                loadLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                loadLoader: false
            })
        })
}

export const onSubmitBusiness = (ep, state, loader, setLoader) => (dispatch, getState) => {
    let data = { ...state }
    data = {
        ...data,
        openingHour: JSON.stringify(data.openingHour)
    }
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                saveLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                saveLoader: false
            })
        })
}

export const onDeleteBusiness = (data, fetchdata) => (dispatch, getState) => {
    commonAxios("delete-business", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchdata()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchBusinessList = (setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-list-business", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
        })
}

export const onGetBusiness = (data, setState, navigate) => (dispatch, getState) => {
    commonAxios("view-business", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data[0] }
                obj = {
                    ...obj,
                    timeFormat: obj.timeformat,
                    openingHour: JSON.parse(obj.openinghours),
                    logo: JSON.parse(obj.logo)
                }
                delete obj.openinghours
                delete obj.timeformat
                setState(obj)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                navigate('/business')
            }
        }).catch((err) => {
        })
}

