import React from 'react'
import { FaBuilding } from "react-icons/fa"
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi"
import Dropdown from 'react-bootstrap/Dropdown'

import dotImg from "../../images/dots.png";
import { onDeleteProduct } from '../../Actions/ProductAction'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ProductDropdownData from './ProductDropdownData';

const ProductRow = ({ curElem, fetchProducts }) => {
    const dispatch = useDispatch()
    const deleteProduct = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Deleting...',
                    timer: 1000
                })
                let data = {
                    id: id
                }
                dispatch(onDeleteProduct(data, fetchProducts))
            }
        })
    }

    const editProduct = (id) => {

    }

    return (
        <tr>
            <td>
                <div className="proThumb">
                    <img src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg" alt="" />
                </div>
            </td>
            <td><strong>{curElem.name}</strong> <br /> <span className="proType">digital</span></td>
            <td>ID-25624</td>
            <td>
                {curElem.mode === "sandbox" ?
                    <span className="pMode sandbox">SandBox</span> :
                    <span className="pMode live">Live</span>
                }
            </td>
            <td>{curElem.price}</td>
            <td>{curElem.modified}</td>
            <td>
                <span className="roundBox"><FiExternalLink /></span>
                <Link className="roundBox" style={{ color: "#002981" }} to={`/create-product?id=${curElem.id}`}><FiEdit /></Link>
                <span className="roundBox" onClick={() => deleteProduct(curElem.id)}><FiTrash2 /></span>
            </td>
            <td>
                <Dropdown className="tableDrop">
                    <Dropdown.Toggle variant="n">
                        <img src={dotImg} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <ProductDropdownData
                            curElem={curElem}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    )
}

export default ProductRow