import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { BiDownArrow } from 'react-icons/bi'

import Calendar from 'react-calendar';

const ProductDesign = () => {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [value, onChange] = useState(new Date());

    return (
        <>
            <div className="designElem">
                <div className='designElem-left'>
                    <div className='secCollapse'>
                    <div className='secCollapse-title' onClick={() => setOpen(!open)}>Background <span><BiDownArrow/></span> </div>
                    <Collapse in={open}>
                        <div className='colorBox'>
                           <ul>
                            <li><div style={{background:'#5D07FE'}} className='color-single'></div></li>
                            <li><div style={{background:'#5EE661'}} className='color-single'></div></li>
                            <li><div style={{background:'#5A70CC'}} className='color-single'></div></li>
                            <li><div style={{background:'#D22F3B'}} className='color-single'></div></li>
                            <li><div style={{background:'#6D6D5C'}} className='color-single'></div></li>
                            <li><div style={{background:'#7A5EBF'}} className='color-single'></div></li>
                            <li><div style={{background:'#B108F3'}} className='color-single'></div></li>
                            <li><div style={{background:'#A4DBE3'}} className='color-single'></div></li>
                            <li><div style={{background:'#CB5D60'}} className='color-single'></div></li>
                            <li><div style={{background:'#A996C2'}} className='color-single'></div></li>
                            <li><div style={{background:'#277FCF'}} className='color-single'></div></li>
                            <li><div style={{background:'#FC6DEB'}} className='color-single'></div></li>
                           </ul>
                        </div>
                    </Collapse>
                    </div>
                    <div className='secCollapse' style={{marginTop:'10px'}}>
                    <div className='secCollapse-title' onClick={() => setOpen2(!open2)}>Logo <span><BiDownArrow/></span> </div>
                    <Collapse in={open2}>
                        <div className='logoBox'>
                           <ul>
                            <li><div className='logoBox-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div></li>
                            <li><div className='logoBox-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div></li>
                            <li><div className='logoBox-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div></li>
                            <li><div className='logoBox-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div></li>
                            <li><div className='logoBox-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div></li>
                            <li><div className='logoBox-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div></li>
                           </ul>
                        </div>
                    </Collapse>
                    </div>
                    <div className='toggleBtn'>
                        <ul>
                            <li>
                                <h6>Call</h6>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <li>
                                <h6>Email</h6>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <li>
                                <h6>Name</h6>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <li>
                                <h6>Address</h6>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <li>
                                <h6>Designation</h6>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='designElem-right'>
                    <div className='designPreview'>
                        <div className='prevLogo'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div>
                        <div className='prevName'>Add Name Here</div>
                        <div className='prevBtn'>
                            <button>Call</button>
                            <button>Email</button>
                        </div>
                        <div className='prevDesignation'>Add Designation Here</div>

                        <div className='prevCalendar'>
                            <h6>Select a Date</h6>
                            <div className='prevCalendar-wrap'>
                              <Calendar onChange={onChange} value={value} />
                            </div>
                        </div>
                        
                        <div className='prevProduct'>
                            <h6>Products</h6>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='prevProduct-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div>
                                </div>
                                <div className='col-6'>
                                    <div className='prevProduct-single'><img src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDesign;