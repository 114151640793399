import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onFetchProducts = (setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-list-product", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onDeleteProduct = (data, fetchData) => (dispatch, getState) => {
    commonAxios("delete-product", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}
export const onGetProduct = (data, setData, navigate) => (dispatch, getState) => {
    commonAxios("view-product", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // setData(res.data[0])
            } else {
                navigate('/product')
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}