import React from "react";
import SocialNetwork from "./SocialNetwork";

import facebookIcon from "../../images/logo-facebook.svg";
import youtubeIcon from "../../images/logo-youtube-grey.svg";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import videoIcon from "../../images/videoreel.svg";

import sonorityIcon from "../../images/logo-sonority.png";

import facebookLogo from '../../images/logo-facebook.svg';
import youtubeLogo from '../../images/YT-DEFAULT.png';
import syvidLogo from '../../images/syvid.svg';
import scriptreelLogo from '../../images/scriptreel.svg';
import videoreelLogo from '../../images/logo-trim.svg';
import livereelLogo from '../../images/livereel.svg';
import sonorityLogo from '../../images/sonority.svg';
import videoLogo from '../../images/videoreel.svg';

const IntegrationHead = ( {socialData} ) => {

    return(
        <>
            
            <div className="inteTop">
                    <div className="container">
                        <div className="inteTop-main my-0">
                           {/* <h2 className="titleBdr">Integrations</h2> */}
                           <ul>
                               <li className={socialData.facebook.length ? '' : 'disable-img'}>
                                  <a href="#facebook">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={facebookLogo} />
                                        <p>Facebook</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                               <li className={socialData.youtube.length ? '' : 'disable-img'}>
                                  <a href="#youtube">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={youtubeLogo} />
                                        <p>YouTube</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                               <li className={socialData.syvid.length ? '' : 'disable-img'}>
                                  <a href="#syvid">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={syvidLogo} />
                                        <p>Syvid</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                               <li className={socialData.scriptreel.length ? '' : 'disable-img'}>
                                  <a href="#scriptreel">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={scriptreelLogo} />
                                        <p>ScriptReel</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                               <li className={socialData.trimreel.length ? '' : 'disable-img'}>
                                  <a href="#trimreel">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={videoreelLogo} />
                                        <p>Trimreel</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                               <li className={socialData.livereel.length ? '' : 'disable-img'}>
                                  <a href="#livereel">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={livereelLogo} />
                                        <p>LiveReel</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                               <li className={socialData.sonority.length ? '' : 'disable-img'}>
                                  <a href="#sonority">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={sonorityLogo} />
                                        <p>Sonority</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                              
                               <li className={socialData.videoreel.length ? '' : 'disable-img'}>
                                  <a href="#videoreel">
                                      <div className="inteTop-single">
                                        <div>
                                        <img alt="" src={videoLogo} />
                                        <p>VideoReel</p>
                                        </div>
                                      </div>
                                  </a>
                               </li>
                           </ul>
                        </div>
                    </div>
                </div>


                <section className="integration-sec d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="integration-head text-white text-center">
                                <h1>Integrations</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 mx-auto">
                            <div className="integration-box">
                                <ul className="list-inline d-flex  flex-wrap justify-content-center">
                                    <SocialNetwork icon={facebookIcon} name="Facebook" />
                                    <SocialNetwork icon={youtubeIcon} name="Youtube" />
                                    <SocialNetwork icon={syvidIcon} name="Syvid" />
                                    <SocialNetwork icon={scriptIcon} name="ScriptReel" />
                                    <SocialNetwork icon={trimIcon} name="TrimReel" />
                                    <SocialNetwork icon={liveIcon} name="LiveReel" />
                                    <SocialNetwork icon={sonorityIcon} name="Sonority" />
                                    <SocialNetwork icon={videoIcon} name="videoreel" />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegrationHead;